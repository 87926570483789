import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import { venuesURL } from "../../common/utils/urls"
import { parseHTML } from "../../common/utils/utils"
import "./StoryBanner.scss"
import StoryDetailSection from "../StoryCard/StoryDetailSection"
import { format } from "date-fns"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"

const StoryBanner = ({ story }) => {
  const { tile_image, title, slug, date, venue, description } = story

  const formattedDate = format(new Date(date), "MMM yyyy")

  return (
    <motion.div
      className="story-banner-wrap"
      variants={contentVariant}
      initial="initial"
      animate="animate"
    >
      <Container className="story-banner-container">
        <h4 className="title">stories</h4>
        <h1 className="heading">{title}</h1>
        <div className="description">
          {parseHTML(description?.data?.description)}
        </div>
        {/* <StoryDetailSection date={formattedDate} venue={venue} /> */}
      </Container>
    </motion.div>
  )
}

export default StoryBanner
