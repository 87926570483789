import { graphql } from "gatsby"
import React from "react"
import "../styles/main.scss"
import SEO from "../components/SEO/seo"
import StoryBanner from "../components/StoryBanner/StoryBanner"
import loadable from "@loadable/component"
import { motion } from "framer-motion"
import { layoutVariant } from "../common/animations"
import Gallery from "../components/Gallery/Gallery"
const StoryImages = loadable(() =>
  import("../components/StoryImages/StoryImages")
)
const StoryText = loadable(() => import("../components/StoryText/StoryText"))
const StoryQuote = loadable(() => import("../components/StoryQuote/StoryQuote"))
const MoreStories = loadable(() =>
  import("../components/MoreStories/MoreStories")
)

const StoryDetailpage = ({ data }) => {
  const story = data.strapiStory

  if (!story) return null

  const { modules, title, strapi_id, images, imagetransforms } = story

  return (
    <motion.div
      className="story-detail-page"
      variants={layoutVariant}
      initial="initial"
      animate="animate"
      exit="exit"
      key={story.title}
    >
      <SEO
        title={story.title}
        description={`Read about ${story.title} here. Discover the benefits of partnering with Blank Canvas for successful and memorable events.`}
        image={story.tile_image?.url}
      />
      <StoryBanner story={story} />
      <Gallery
        name={title}
        strapi_id={strapi_id}
        images={images}
        imagetransforms={imagetransforms}
        imagename="story.images.gallery_image"
      />
      <div className="story-modules">
        {modules?.map(module => (
          <>
            {module.strapi_component === "components.image" && (
              <StoryImages images={[module.image]} story={story} />
            )}
            {module.strapi_component === "components.multi-image" && (
              <StoryImages images={module.images} story={story} />
            )}
            {module.strapi_component === "components.rich-text" && (
              <StoryText text={module.text_content?.data?.text_content} />
            )}
            {module.strapi_component === "components.quote" && (
              <StoryQuote author={module.author} quote={module.quote} />
            )}
          </>
        ))}
      </div>
      <MoreStories storyID={story.id} />
    </motion.div>
  )
}

export default StoryDetailpage

export const query = graphql`
  query ($story_id: Int) {
    strapiStory(strapi_id: { eq: $story_id }) {
      ...storyFragment
    }
  }
`
